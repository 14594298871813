

$(function() {
  "use strict";
  //ヘッダー固定
  //$(window).on('load scroll', function () {

  //var vH = 100;
  //if ($(window).scrollTop() > vH) {
  // $('#header-top-fixed').addClass('fixed');
  //} else {
  //  $('#header-top-fixed').removeClass('fixed');
  //}
  //});
  //login-btn
  $("#js-login-open").click(function() {
    $(this).toggleClass("active");
    if ($(this).hasClass("active")) {
      $("#js-login-open").addClass("active");
      $("#js-login-menu").addClass("active");
    } else {
      $("#js-login-open").removeClass("active");
      $("#js-login-menu").removeClass("active");
    }
  });
  //SP-nav
  $("#js-menu-btn").click(function() {
    $(this).toggleClass("active");
    if ($(this).hasClass("active")) {
      $("#js-menu-btn").addClass("active");
      $("#js-menu").addClass("active");
    } else {
      $("#js-menu-btn").removeClass("active");
      $("#js-menu").removeClass("active");
    }
  });
  //PC-nav
  $("#js-menu-btn-pc").click(function() {
    $(this).toggleClass("active");
    if ($(this).hasClass("active")) {
      $("#js-menu-btn-pc").addClass("active");
      $("#js-menu-pc").addClass("active");
      $("#js-menu-pc-back").addClass("active");
    } else {
      $("#js-menu-btn-pc").removeClass("active");
      $("#js-menu-pc").removeClass("active");
      $("#js-menu-pc-back").removeClass("active");
    }
  });
  //PC-nav
  $("#js-menu-pc-back").click(function() {
    $("#js-menu-btn-pc").removeClass("active");
    $("#js-menu-pc").removeClass("active");
    $("#js-menu-pc-back").removeClass("active");
  });
  //page-top
  var pagetop = $("#js-pagetop");
  $(window).scroll(function() {
    if ($(this).scrollTop() > 500) {
      pagetop.addClass("active");
    } else {
      pagetop.removeClass("active");
    }
  });
  pagetop.click(function() {
    $("body, html").animate(
      {
        scrollTop: 0,
      },
      800,
      "swing"
    );
    return false;
  });
  //SCROLL
  $('a[href^="#"]').click(function() {
    var speed = 800;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("body,html").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
  //inview

  $(window).scroll(function() {
    var c = jQuery(window).height(),
      b = jQuery(window).scrollTop();
    $(".slide-in-left").each(function() {
      var d = jQuery(this).offset().top;
      if (b > d - c + 200) {
        jQuery(this).addClass("slide-in-left-anime");
      }
    });
  });
  $(window).scroll(function() {
    var c = jQuery(window).height(),
      b = jQuery(window).scrollTop();
    $(".slide-in-right").each(function() {
      var d = jQuery(this).offset().top;
      if (b > d - c + 200) {
        jQuery(this).addClass("slide-in-right-anime");
      }
    });
  });
  $(window).scroll(function() {
    var c = jQuery(window).height(),
      b = jQuery(window).scrollTop();
    $(".fade-in").each(function() {
      var d = jQuery(this).offset().top;
      if (b > d - c + 200) {
        jQuery(this).addClass("fade-in-anime");
      }
    });
  });
});
